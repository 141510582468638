import React, { useState } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import pass from "../pass.json";
import question from "../question.json";
import cross from "../cross.json";
import logo from "../logo.png";


import "../App.css"; // Importáltuk az App.css fájlt a stílusok beállításához

const WarrantyCheck = () => {
  const [imei, setImei] = useState("");
  const [garantiaData, setGarantiaData] = useState(null);

  const checkGarantia = async () => {
    try {
      const response = await axios.get(`https://leltar.slimstore.hu/garancia.php?imei=${imei}`);
      setGarantiaData(response.data);
    } catch (error) {
      console.error("Error fetching garantia data", error);
      setGarantiaData(null);
    }
  };
  const WarrantyStatus = ({ garancia }) => {
      if (garancia[0].webshopId === "0") {
        return <p className="warrantyonTitle">Garancia érvényes</p>;
    } else if (garancia[0].checked === "0") {
        return <p className="warrantyonTitle">Garancia ellenőrzés alatt</p>;
    } else {
        return null; // Or return any other default value if needed
    }
    
};
  const renderGarantiaStatus = () => {
    if ( garantiaData != null) {
      if (garantiaData.length === 0) {
        return (<div className="warrantyno">
        <p className="warrantynoTitle">Nem található ezzel az<br></br> IMEI számmal garanciajegy.</p>
        <Lottie style={{height:"80px"}} animationData={question} loop={false}/>
  
      </div>);
      } else {
        const currentDate = new Date();
        const startDate = new Date(garantiaData[0].date);
      
        // Állítsuk be az expirationDate-t a startDate-től 365 napra előre
        const expirationDate = new Date(startDate);
        expirationDate.setDate(expirationDate.getDate() + 365);
      
        const isGarantiaValid = expirationDate > currentDate;
      
        let remainingDays = null;
      
        if (isGarantiaValid) {
          const timeDiff = expirationDate.getTime() - currentDate.getTime();
          remainingDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        }
        console.log(garantiaData)
        return (
          <div>
            {isGarantiaValid ? (
              <div className="warrantyon">
                <WarrantyStatus garancia={garantiaData}/>
                <p>Név: <b>{garantiaData[0]["name"]}</b></p>
                <p>E-mail: <b>{garantiaData[0]["email"]}</b></p>
                {garantiaData[0]["image1"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image1"]} target="_blank"><p className='image'>Előlap kép: sarok 1.</p></a>
)}
{garantiaData[0]["image2"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image2"]} target="_blank"><p className='image'>Előlap kép: sarok 2.</p></a>
)}
{garantiaData[0]["image3"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image3"]} target="_blank"><p className='image'>Előlap kép: sarok 3.</p></a>
)}
{garantiaData[0]["image4"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image4"]} target="_blank"><p className='image'>Előlap kép: sarok 4.</p></a>
)}
{garantiaData[0]["image5"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image5"]} target="_blank"><p className='image'>Hátlap kép: sarok 1.</p></a>
)}
{garantiaData[0]["image6"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image6"]} target="_blank"><p className='image'>Hátlap kép: sarok 2.</p></a>
)}
{garantiaData[0]["image7"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image7"]} target="_blank"><p className='image'>Hátlap kép: sarok 3.</p></a>
)}
{garantiaData[0]["image8"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image8"]} target="_blank"><p className='image'>Hátlap kép: sarok 4.</p></a>
)}
                <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image9"]} target="_blank"><p className="image">Készülék IMEI + számla</p></a>
                <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image10"]} target="_blank"><p className="image">Garanciális feltételek</p></a>
                <hr></hr>
                <p>Termék(ek): <b>{garantiaData[0]["products"]}</b></p>
                <p>Garancia típusa: <b>{garantiaData[0]["garanciaType"]}</b></p>
                <p>Garancia időtartama: <b>1 év</b></p>
                <br></br>
                <p>Még <b><h2>{remainingDays}</h2></b> nap a garanciából.</p>
                <Lottie style={{height:"80px"}} animationData={pass} loop={false}/>
    
              </div>
            ) : (
              <div className="warrantyoff">
                <p className="warrantyoffTitle">Garancia nem érvényes</p>
                <p>Név: <b>{garantiaData[0]["name"]}</b></p>
                <p>E-mail: <b>{garantiaData[0]["email"]}</b></p>
                {garantiaData[0]["image1"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image1"]} target="_blank"><p className='image'>Előlap kép: sarok 1.</p></a>
)}
{garantiaData[0]["image2"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image2"]} target="_blank"><p className='image'>Előlap kép: sarok 2.</p></a>
)}
{garantiaData[0]["image3"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image3"]} target="_blank"><p className='image'>Előlap kép: sarok 3.</p></a>
)}
{garantiaData[0]["image4"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image4"]} target="_blank"><p className='image'>Előlap kép: sarok 4.</p></a>
)}
{garantiaData[0]["image5"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image5"]} target="_blank"><p className='image'>Hátlap kép: sarok 1.</p></a>
)}
{garantiaData[0]["image6"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image6"]} target="_blank"><p className='image'>Hátlap kép: sarok 2.</p></a>
)}
{garantiaData[0]["image7"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image7"]} target="_blank"><p className='image'>Hátlap kép: sarok 3.</p></a>
)}
{garantiaData[0]["image8"] != "garancia_uploads/" && (
  <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image8"]} target="_blank"><p className='image'>Hátlap kép: sarok 4.</p></a>
)}
                <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image9"]} target="_blank"><p className="image">Készülék IMEI + számla</p></a>
                <a href={"https://leltar.slimstore.hu/" + garantiaData[0]["image10"]} target="_blank"><p className="image">Garanciális feltételek</p></a>
                <hr></hr>
                <p>Termék(ek): <b>{garantiaData[0]["products"]}</b></p>
                <p>Garancia típusa: <b>{garantiaData[0]["garanciaType"]}</b></p>
                <p>Garancia időtartama: <b>1 év</b></p>
                <br></br>
                <Lottie style={{height:"80px"}} animationData={cross} loop={false}/>
    
              </div>
            )}
          </div>
        );
      }
      
    } else {
      return null;
    }
  
    
  };

  return (
    <div className="pageContainer">
      <div className="logoContainer">
        <img src={logo} alt="Logo" height={"150px"} />
      </div>
      <h1>Garancia Ellenőrző</h1>
      <div className="inputContainer">
        <input
          type="text"
          placeholder="IMEI szám"
          className="inputStyle"
          value={imei}
          onChange={(e) => {
            const filteredValue = e.target.value.replace(/\s/g, '');
            setImei(filteredValue);
          }}
        />
        <button className="buttonStyle" onClick={checkGarantia}>
          Ellenőrzés
        </button>
      </div>
      {renderGarantiaStatus()}
    </div>
  );
  

};

export default WarrantyCheck;
