// App.js

import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import "./App.css"; // Importáltuk az App.css fájlt a stílusok beállításához
import WarrantyCheck from "./Compontents/WarrantyCheck";
import WarrantyUpload from "./Compontents/WarrantyUpload";

function App() {
  return (
    <div className="App">
      
        <BrowserRouter>
            <Routes>
              <Route path="/" element={<WarrantyCheck />} />
              <Route path="/upload/:id" element={<WarrantyUpload />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
