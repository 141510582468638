import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import Lottie from "lottie-react";
import loading from "../loading.json";
import { PDFDocument, rgb, PNGImage } from 'pdf-lib';
import logo from "../logo.png";
import pdf from './garancia.pdf';
import StepIndicator from "./StepIndicator";
import imageCompression from 'browser-image-compression';
import SignatureCanvas from 'react-signature-canvas';

import {getDocument} from 'pdfjs-dist/webpack';
import { Tooltip } from 'react-tooltip'
import "../App.css"; // Importáltuk az App.css fájlt a stílusok beállításához

const WarrantyUpload = () => {
  let { id } = useParams();
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [image6, setImage6] = useState(null);
  const [image7, setImage7] = useState(null);
  const [image8, setImage8] = useState(null);
  const [image9, setImage9] = useState(null);
  const [image10, setImage10] = useState(null);
  const [videoEnded, setVideoEnded] = useState(false);
  const videoRef = useRef(null);
  const signature2Ref = useRef();
  const clearSignature2 = () => {
    signature2Ref.current.clear();
  };
  const [imei, setImei] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setIsProcessin] = useState(false);
  const [completed, setIsCompleted] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  function generateRandomName() {
    return 'SCR-' + Date.now() + '-' + Math.random().toString(36).substring(2, 8);
  }
  
  async function convertPngUrlsToBlobs(pngUrls) {
    try {
        const blobPromises = [];
  
        // Minden PNG URL-hez fetch-et hajtunk végre
        for (const pngUrl of pngUrls) {
            const response = await fetch(pngUrl);
            const blob = await response.blob();
  
            // Véletlenszerű név generálása
            const randomName = generateRandomName() + '.png';
  
            // Blob objektum létrehozása a megadott tulajdonságokkal
            const blobWithProperties = new Blob([blob], { type: 'image/png' });
            blobWithProperties.name = randomName;
            blobWithProperties.lastModified = Date.now();
  
            blobPromises.push(blobWithProperties);
        }
  
        // Várunk, amíg az összes blob létrejön
        const blobs = await Promise.all(blobPromises);
  
        return blobs;
    } catch (error) {
      window.alert('Hiba történt a blobok létrehozása során:', error);
        console.error('Hiba történt a blobok létrehozása során:', error);
        return [];
    }
  }
    async function createPdfBlobWithInfo(signature1Ref, signature2Ref, date) {
      const pdfDoc = await addSignaturesAndDateToPDF(signature1Ref, signature2Ref, date);
      const pdfBytes = await pdfDoc.save();
      const uint8Array = new Uint8Array(pdfBytes);
  
      // A fájl betöltése
      convertPdfToPng(uint8Array)
      .then(pngUrls => {
          convertPngUrlsToBlobs(pngUrls)
      .then(blobs => {
          setImage10(blobs[0]);
      })
      .catch(error => {
          console.error('Hiba történt a blobok létrehozása során:', error);
          window.alert('Hiba történt a blobok létrehozása során:', error);
      });
      })
      .catch(error => {
          console.error('Hiba történt a PDF konvertálása során:', error);
          window.alert(error, error);
      });
    }
    const handlePDF = async () => {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];
      createPdfBlobWithInfo( signature2Ref, formattedDate);
      };
  
    const addSignaturesAndDateToPDF = async (signature2Ref, date) => {
      const existingPdfBytes = await fetch(pdf).then(res => res.arrayBuffer());
      const bytes = new Uint8Array(existingPdfBytes);
      const pdfDoc = await PDFDocument.load(bytes);
      const page = pdfDoc.getPages(0);
      const signature2Image = await pdfDoc.embedPng(signature2Ref.current.toDataURL());
      page[0].drawImage(signature2Image, {
          x: 400,
          y: 115,
          width: 100,
          height: 50,
      });
      page[0].drawText(date, {
          x: 105,
          y: 165,
          size: 11,
          color: rgb(0, 0, 0),
      });
      return pdfDoc;
  };
  async function convertPdfToPng(pdfUrl) {
    try {
        const loadingTask = getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        const pngUrls = [];
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1.0 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
          // PNG kép készítése a lapról
        await page.render({
          canvasContext: context,
          viewport: viewport
        }).promise;
          // PNG kép URL-ének létrehozása a canvasról
        const pngUrl = canvas.toDataURL('image/png');
          // Hozzáadjuk a PNG URL-t a tömbhöz
        pngUrls.push(pngUrl);
        return pngUrls;
    } catch (error) {
        console.error('Hiba történt a PDF konvertálása során:', error);
        window.alert(error, error);
        return [];
    }
}
  const displayPDF = () => {
    const pdfUrl = 'https://leltar.slimstore.hu/garancia.pdf';
    window.open(pdfUrl);
  };
    // Ellenőrzési logika
    const [elolapBejelolve, setelolapBejelolve] = useState(false);
    const [hatlapBejelolve, sethatlapBejelolve] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      const consumerKey = 'ck_3f6d76da4a3043b0a1227596ca065fa1d73639b6';
      const consumerSecret = 'cs_42e68c9bbfc4e4831e2333974615b9b0a75eff8e';
      const url = `https://slimstore.hu/wp-json/wc/v3/orders/${id}`;

      try {
        const response = await axios.get(url, {
          auth: {
            username: consumerKey,
            password: consumerSecret
          }
        });
        const url2 = `https://leltar.slimstore.hu/garancia.php?webshopId=${id}`;
      try {
        const response2 = await axios.get(url2);
        if(response.data.status === 'completed'){
          for (let i = 0; i < response2.data.length; i++) {
            let productNames = response2.data[i].products.split(',').map(name => name.trim().toUpperCase());
              removeProductOnce(productNames, response.data.line_items);
          }
          setOrders(response.data);
          setIsLoaded(true);
        }
        
        setName(response.data.billing.last_name + ' ' + response.data.billing.first_name);
        setEmail(response.data.billing.email);
        
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }};

    fetchOrders();
  }, []);
  // Function to find and remove the first occurrence of a product from line_items
function removeProductOnce(products, line_items) {
  for (let productName of products) {
    for (let i = 0; i < line_items.length; i++) {
      if (line_items[i].name.toUpperCase() === productName || line_items[i].name.toUpperCase().includes(productName)) {
        line_items.splice(i, 1);
        break; // Stop after finding the first match
      }
    }
  }
}
  const compressAndLimitSize = async (file, maxSizeInMB, setImage) => {
    try {
      const options = {
        maxSizeMB: maxSizeInMB, // A maximális méret, amire tömöríteni szeretnénk (1 MB például)
        maxWidthOrHeight: 800, // A kép maximális szélessége vagy magassága
        useWebWorker: true, // WebWorker használata a tömörítéshez (opcionális)
      };
  
      const compressedFile = await imageCompression(file, options);
      setImage(compressedFile);
    } catch (error) {
      window.alert('Hiba a kép tömörítése közben:', error);
    }
  };
  const handleVideoEnd = () => {
    setVideoEnded(true);
  };

  const handleVideoTimeUpdate = () => {
    // Check if the video is being scrubbing
    if (videoRef.current && videoRef.current.currentTime < videoRef.current.duration) {
      setVideoEnded(false);
    }
  };
  const renderPage3 = () => {
    return (
      <div>
      <video
        ref={videoRef}
        width="600"
        controls={false}
        autoPlay={true}
        onEnded={handleVideoEnd}
        onTimeUpdate={handleVideoTimeUpdate}
      >
        <source src="https://garancia.slimstore.hu/upload/garancia.mov" type="video/mp4" />
      </video>
    </div>
    )
  }
  const renderPage4 = () => {
    return (
      <div>
        {elolapBejelolve && (
          <>
            <div className="form-group">
              <label htmlFor="image1" className="inputLabel">Előlap kép: sarok 1.</label>
              <input
                type="file"
                className="form-control"
                id="image1"
                onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage1)}
                required={elolapBejelolve}
              />
            </div>
            <div className="form-group">
              <label htmlFor="image2" className="inputLabel">Előlap kép: sarok 2.</label>
              <input
                type="file"
                className="form-control"
                id="image2"
                onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage2)}
                required={elolapBejelolve}
              />
            </div>
            <div className="form-group">
              <label htmlFor="image3" className="inputLabel">Előlap kép: sarok 3.</label>
              <input
                type="file"
                className="form-control"
                id="image3"
                onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage3)}
                required={elolapBejelolve}
              />
            </div>
            <div className="form-group">
              <label htmlFor="image4" className="inputLabel">Előlap kép: sarok 4. </label>
              <input
                type="file"
                className="form-control"
                id="image4"
                onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage4)}
                required={elolapBejelolve}
              />
            </div>
          </>
        )}
        {hatlapBejelolve && (
          <>
            <div className="form-group">
              <label htmlFor="image5" className="inputLabel">Hátlap kép: sarok 1.</label>
              <input
                type="file"
                className="form-control"
                id="image5"
                onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage5)}
                required={hatlapBejelolve}
              />
            </div>
            <div className="form-group">
              <label htmlFor="image6" className="inputLabel">Hátlap kép: sarok 2.</label>
              <input
                type="file"
                className="form-control"
                id="image6"
                onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage6)}
                required={hatlapBejelolve}
              />
            </div>
            <div className="form-group">
              <label htmlFor="image7" className="inputLabel">Hátlap kép: sarok 3.</label>
              <input
                type="file"
                className="form-control"
                id="image7"
                onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage7)}
                required={hatlapBejelolve}
              />
            </div>
            <div className="form-group">
              <label htmlFor="image8" className="inputLabel">Hátlap kép: sarok 4. </label>
              <input
                type="file"
                className="form-control"
                id="image8"
                onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage8)}
                required={hatlapBejelolve}
              />
            </div>
          </>
        )}
        <div className="form-group">
          <label htmlFor="image9" className="inputLabel">Készülék IMEI <a data-tooltip-id="my-tooltip" data-tooltip-content="Tölts fel egy képet a telefonodról (fényképezve, ne képernyőfotót), ahol látszik a telefon IMEI száma!">
          &#9432;
</a></label>
          <input
            type="file"
            className="form-control"
            id="image9"
            onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage9)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="image10" className="inputLabel">Garanciális feltételek</label><br />
          <button style={{border: "2px solid", backgroundColor: "white", color: "black", marginTop: "10px", borderRadius: "10px", fontSize: "12px"}} onClick={displayPDF}>Megtekintés</button>
        </div>
        <hr />
        <p className='inputLabel'>Garanciális feltételek aláírása:</p>
        <div id="signatureDiv">
          <SignatureCanvas style={{borderRadius: "15px"}} ref={signature2Ref} penColor="black" canvasProps={{ width: 300, height: 150, borderRadius: "15px" }} />
        </div>
        <button style={{border: "2px solid", backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}} onClick={clearSignature2}>Törlés</button>
        <hr />
        <button onClick={() => handleSubmit()} style={{marginTop: "20px"}} className="nextButton">Garancia feltöltése</button>
      </div>
    )
  }
  
  const handleStepChange = (step) => {
    setCurrentStep(step);
  }
  const handleBack = (step) => {
    setImage1(null);
    setImage2(null);
    setImage3(null);
    setImage4(null);
    setImage5(null);
    setImage6(null);
    setImage7(null);
    setImage8(null);
    setImage9(null);
    setCurrentStep(step);
  }
  const generateUniqueFileName = (file, imageint) => {
    const timestamp = new Date().getTime();
    const uniqueName = `${timestamp}_${imageint}_${file.name}`;
    return uniqueName;
  };
  const handleSubmit = async () => {
    
    try {
      await handlePDF();
  } catch (error) {
    window.alert('Hiba történt!', error);
      return; 
  }
    if (elolapBejelolve && (image1 == null || image2 == null || image3 == null || image4 == null)) {
      window.alert("Kérjük töltsd fel az előlap képeket!");
  } else if (hatlapBejelolve && (image5 == null || image6 == null || image7 == null || image8 == null)) {
      window.alert("Kérjük töltsd fel a hátlap képeket!");
  } else if (elolapBejelolve && hatlapBejelolve && 
             (image1 == null || image2 == null || image3 == null || image4 == null || 
              image5 == null || image6 == null || image7 == null || image8 == null)) {
      window.alert("Kérjük töltsd fel az összes képet!");
  } else {
    if(image9 == null) {
      window.alert("Kérjük töltsd fel a készülék IMEI számát!");
    } else {
      if(signature2Ref.current.isEmpty()) {
        window.alert("Kérjük írd alá a garanciális feltételeket!");
      } else {
        
        const formData = new FormData();
    formData.append('imei', imei);
    formData.append('name', name);
    formData.append('userId', '0');
    formData.append('email', email);
    formData.append('webshopId', id);
    formData.append('checked', 0)
    // Ellenőrizze és appendelje csak a nem null image-eket
    if (image1) formData.append('image1', image1, generateUniqueFileName(image1, 1));
    if (image2) formData.append('image2', image2, generateUniqueFileName(image2, 2));
    if (image3) formData.append('image3', image3, generateUniqueFileName(image3, 3));
    if (image4) formData.append('image4', image4, generateUniqueFileName(image4, 4));
    if (image5) formData.append('image5', image5, generateUniqueFileName(image5, 5));
    if (image6) formData.append('image6', image6, generateUniqueFileName(image6, 6));
    if (image7) formData.append('image7', image7, generateUniqueFileName(image7, 7));
    if (image8) formData.append('image8', image8, generateUniqueFileName(image8, 8));
    formData.append('image9', image9, generateUniqueFileName(image9, 9));
    formData.append('image10', image10, generateUniqueFileName(image10, 10));
    selectedProducts.forEach((product) => {
      switch (product.name.toLowerCase().split(' - ')[0]) {
        case 'privacy üvegfólia':
          formData.append('termek[]', 'PRIVACY ÜVEGFÓLIA');
          break;
        case 'átlátszó tok':
          formData.append('termek[]', 'procase');
          break;
        case 'magsafe tok':
          formData.append('termek[]', 'magsafe');
          break;
        case 'hd üvegfólia':
          formData.append('termek[]', 'HD ÜVEGFÓLIA');
          break;
        case 'matt privacy fólia':
          formData.append('termek[]', 'privacyelo');
          break;
        case 'uv fólia':
          formData.append('termek[]', 'UVelo');
          break;
        case 'szilikon tok':
          formData.append('termek[]', 'slimcase');
          break;
        default:
          return
      }
    });
    try {
      setIsProcessin(true);
      await axios.post('https://leltar.slimstore.hu/garancia.php', formData).then(res => {
        setIsProcessin(false);
        setIsCompleted(true);
    });
    } catch (error) {
      window.alert('Hiba történt: ' + error);
      console.error('Error saving data:', error);
    }
  }
    }
    
  }
  


    
  }
  const handleProductNextStep = (step) => {
    if(selectedProducts.length == 0) {
      window.alert("Kérjük válassz ki legalább egy terméket!");
    } else {
      if((selectedProducts.some(product1 => product1.name.includes("FÓLIA")))){
        setelolapBejelolve(true);
      } else {
        setelolapBejelolve(false);
      }

      if((selectedProducts.some(product1 => product1.name.includes("TOK")))){
        sethatlapBejelolve(true);
      } else {
        sethatlapBejelolve(false);
      }
    setCurrentStep(step);
    }
  }
  const handleProductSelect = (product) => {
    if (selectedProducts.some(product1 => product1.name.includes("FÓLIA") && product.name.includes("FÓLIA") && !selectedProducts.includes(product)) || selectedProducts.some(product1 => product1.name.includes("TOK") && product.name.includes("TOK") && !selectedProducts.includes(product))){
      window.alert("Nem tudsz kiválasztani 2 fóliát vagy két tokot egy garanciához!")
    } else {
      if(selectedProducts.includes(product)) {
        setSelectedProducts(selectedProducts.filter(p => p !== product));
      } else {
      setSelectedProducts([...selectedProducts, product]);
      }
    }
    
  }
  const userData = () => {
    return (
      <div>
      <p className="inputLabel">Név:</p>
            <input
              type="name"
              className="mobileInput"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <p className="inputLabel">E-mail:</p>
            <input
              type="name"
              className="mobileInput"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <p className="inputLabel">Készülék IMEI száma:</p>
            <input
              type="name"
              className="mobileInput"
              id="imei"
              value={imei}
              onChange={(e) => setImei(e.target.value)}
              required
            />
      </div>
    )
  }
  const renderProduct = (product) => {
    let imageSrc = '';
    let productName = '';
    let productType = product.name.split(' - ')[1] ?? product.meta_data[0].value;
    switch (product.name.toLowerCase().split(' - ')[0]) {
      case 'privacy üvegfólia':
        imageSrc = 'https://slimstore.hu/wp-content/uploads/2024/03/privacy_uvegfolia-600x600.webp';
        productName = 'PRIVACY ÜVEGFÓLIA';
        break;
      case 'átlátszó tok':
        imageSrc = 'https://slimstore.hu/wp-content/uploads/2023/04/slimstore_tok-1-600x600.webp';
        productName = 'ÁTLÁTSZÓ TOK';
        break;
      case 'magsafe tok':
        imageSrc = 'https://slimstore.hu/wp-content/uploads/2023/04/slimstore_magsafe-600x600.webp';
        productName = 'MAGSAFE TOK';
        break;
      case 'hd üvegfólia':
        imageSrc = 'https://slimstore.hu/wp-content/uploads/2024/03/hd_uvegfolia-600x600.webp';
        productName = 'HD ÜVEGFÓLIA';
        break;
      case 'matt privacy fólia':
        imageSrc = 'https://slimstore.hu/wp-content/uploads/2023/02/privacyslim-600x600.webp';
        productName = 'MATT PRIVACY FÓLIA';
        break;
      case 'uv fólia':
        imageSrc = 'https://slimstore.hu/wp-content/uploads/2023/02/hdslim-600x600.webp';
        productName = 'UV FÓLIA';
        break;
      case 'szilikon tok':
        imageSrc = 'https://slimstore.hu/wp-content/uploads/2023/02/sillicon_tok-600x600.png';
        productName = 'SZILIKON TOK';
        break;
        case 'hátlapi fólia':
          imageSrc = 'https://slimstore.hu/wp-content/uploads/2024/07/hatlapifolia.png';
          productName = 'HÁTLAPI FÓLIA';
          break;
      default:
          imageSrc = '';
          productName = product.name;
          break;
        return
    }
    const border = (product) => {
      return selectedProducts.includes(product) ? '3px dashed #4caf50' : '1px dashed #000000';
    }
    return (
      <div key={product.id} style={{ border: border(product) }} className="productDiv" onClick={(e) => handleProductSelect(product)}>
        <img src={imageSrc} alt={productName} style={{ width: '100px', height: '100px' }} />
        <p className="productName">{productName} </p>
        {productType && <p className="productType">{productType}</p>}
      </div>
    );
  };

  return (
    <div className="pageContainer">
      <div className="logoContainer">
        <img src={logo} alt="Logo" height={"150px"} />
      </div>
      <h1>Garancia feltöltés</h1>
      {!isLoaded && <p><Lottie style={{height:"80px"}} animationData={loading} loop={true}/></p>}
      {isLoaded && <StepIndicator currentStep={currentStep+1} /> }
      {isLoaded &&<hr className="style-two"></hr> }
      <br></br>{isLoaded && currentStep > 0 && !processing && !completed && <button onClick={() => handleBack(currentStep - 1)} style={{marginTop: "5px", marginBottom: "10px"}} className="nextButton">Vissza</button>}
      <br></br>
      {isLoaded && currentStep == 0 && <div><h3 style={{margin: "0px"}}>Megvásárolt termékek</h3><p style={{margin: "5px", fontSize: "13px"}}>Válaszd ki melyikre szeretnéd érvényesíteni a garanciát</p><br></br></div>}
      {isLoaded && currentStep == 1 && <div><h3 style={{margin: "0px"}}>Adatok</h3><p style={{margin: "5px", fontSize: "13px"}}>Kérjük add meg a szükséges adatokat</p><br></br></div>}
      {isLoaded && currentStep == 1 && userData()}
      {isLoaded && currentStep == 2 && !videoEnded && <div><h3 style={{margin: "0px"}}>Videó</h3><p style={{margin: "5px", fontSize: "13px"}}>Kérjük nézd végig a videót!</p><br></br></div>}
      {isLoaded && currentStep == 2 && !videoEnded && renderPage3()}
      {isLoaded && currentStep == 2 && videoEnded && <button onClick={() => setVideoEnded(false)} style={{marginTop: "20px"}} className="nextButton">Videó újranézése</button>}
      {isLoaded && currentStep == 3 && !processing && !completed && renderPage4()}
      {processing && <p><Lottie style={{height:"80px"}} animationData={loading} loop={true}/></p>}
      {completed && <p><h2>Sikeres garancia feltöltés! Promótereink hamarosan ellenőrzik a feltöltésed és ha mindent rendben találnak értesítünk a jóváhagyásról!</h2></p>}
      <div className="productsDiv">
      {isLoaded && currentStep == 0 && orders.line_items.map(product => renderProduct(product))}
      <Tooltip id="my-tooltip" />
      </div>
      <br></br>{isLoaded && currentStep == 2 && videoEnded && <button onClick={() => handleStepChange(3)} style={{marginTop: "20px"}} className="nextButton">Tovább</button>}
      <br></br>{isLoaded && currentStep == 1 && name != "" && imei != "" && email != "" && <button onClick={() => handleStepChange(2)} style={{marginTop: "20px"}} className="nextButton">Tovább</button>}
      <br></br>{isLoaded && currentStep == 0 && selectedProducts.length > 0 && <button onClick={() => handleProductNextStep(1)} style={{marginTop: "20px"}} className="nextButton">Tovább</button>}
    </div>
  );
  

};

export default WarrantyUpload;
