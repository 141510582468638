import React from 'react';
import './StepIndicator.css';

const StepIndicator = ({ currentStep }) => {
  const steps = [1, 2, 3, 4];

  return (
    <div className="step-indicator-container">
      <div className="steps">
        {steps.map(step => (
          <div>
          <div key={step} className={`step ${currentStep == step ? 'active' : ''}`}>
            <div className="step-number">{step}.</div>
            
          </div>
          <div className="step-label">lépés</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepIndicator;